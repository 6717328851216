module.exports = {
  // App Info
  app_description_color: '#ffffff',
  app_description:
    '¿Cansado de visitar la página web de Renfe para comprobar si ya están disponibles los trenes? ¡Con Vigilante Renfe puedes olvidarte de eso!',
  app_keywords: ['renfe', 'alertas', 'tren', 'publicar'],
  app_name: 'Vigilante Renfe',
  app_price_color: '#ffffff',
  app_price: 'Gratis',
  app_title_color: '#ffffff',
  app_url: 'https://vigilanterenfe.es',
  appstore_link: '',
  author: 'Miguel Sánchez',
  blend_color: '#C00B3F',
  body_background_color: '#ffffff',
  content_width: '1170px',
  email_address: 'hola@vigilanterenfe.es',
  facebook_page: 'vigilanterenfeapp',
  feature_icons_background_color: '#e6e6e6',
  feature_text_color: '#666666',
  feature_title_color: '#000000',
  features: [
    {
      title: 'Programa Vigilantes',
      description:
        'Crea alertas para el trayecto y el día en el que quieras viajar. Los vigilantes comprobarán por ti la disponibilidad de trenes',
      fontawesome_icon_name: 'magic',
    },
    {
      title: 'Recibe Noticaciones',
      description:
        'Te enviaremos una notificación cuando los trenes para el trayecto seleccionado estén disponibles',
      fontawesome_icon_name: 'star',
    },
    {
      title: 'Elige el billete',
      description:
        'Podrás ver toda la información a cerca de los trenes disponibles y navegar directamente a la página web de Renfe para comprar el billete',
      fontawesome_icon_name: 'info',
    },
  ],
  font: `"Lato", "Helvetica Neue", sans-serif`,
  footer_text_color: '#666666',
  google_analytics_ID: 'UA-150796956-1',
  header_background: 'rgba(0, 0, 0, 0.1)',
  linkedin_username: null,
  path_prefix: '/',
  playstore_link:
    'https://play.google.com/store/apps/details?id=es.mschez.renfewatcher',
  primary_color: '#81005E',
  primary_text_color: '#000',
  secondary_color: '#FF1721',
  social_icons_background_color: '#e6e6e6',
  social_icons_foreground_color: '#666666',
  title_font: `"Yanone Kaffeesatz", "Helvetica Neue", sans-serif`,
  topbar_title_color: '#ffffff',
  twitter_username: null,
};
